import React from "react";
import BlockLayout from "../../blocks/BlockLayout";
import WorkSlider from '../WorkSlider'

const RelatedWork = ({work}) => {
  if (!work?.nodes || work.nodes === 0 || work.nodes.length === 0) return null;
  return (
    <BlockLayout heading="More Work">
      <WorkSlider work={work.nodes} />
    </BlockLayout>
  );
};

export default RelatedWork;
