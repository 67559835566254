import React from "react";
import { graphql } from "gatsby";
import RenderBlocks from "../../blocks/renderBlocks";
import PageLayout from "../../layout/PageLayout";
import PageTitle from "../../components/PageTitle";
import { ClientLabel, CategoryLabel } from "./style";
import RelatedWork from "../../components/RelatedWork";
const work = ({ data }) => {
  const { page, work } = data;
  const {
    title,
    pageOptions,
    featuredImage,
    workcategories,
    relatedContent: { clientWorkRelationship },
    contentBlocks: { blocks },
  } = page;
  const landscapeImage = pageOptions?.landscapeImage;
  const Image = landscapeImage
    ? landscapeImage
    : featuredImage?.node
    ? featuredImage.node
    : false;
  const before = clientWorkRelationship
    ? clientWorkRelationship.map((item, key) => (
        <ClientLabel key={`work-client-${key}`}>{item.title}</ClientLabel>
      ))
    : null;
  const Cat = workcategories?.nodes
    ? workcategories.nodes.map((item, key) => (
        <CategoryLabel key={`work-label-${key}`}>{item.name}</CategoryLabel>
      ))
    : null;
  return (
    <PageLayout data={data}>
      {pageOptions ? (
        !pageOptions.hidePageTitle && (
          <PageTitle heading={title} featureImage={Image} before={before}>
            {Cat}
          </PageTitle>
        )
      ) : (
        <PageTitle heading={title} featureImage={Image} before={before}>
          {Cat}
        </PageTitle>
      )}
      <RenderBlocks blocks={blocks} />
      <RelatedWork work={work} />
    </PageLayout>
  );
};

export default work;

export const query = graphql`
  query work($id: String!, $workcategories: [String]) {
    page: wpContentNode(id: { eq: $id }) {
      ... on WpWork {
        ...WorkContent
      }
    }
    work: allWpWork(
      limit: 5
      filter: {
        id: { ne: $id }
        workcategories: {
          nodes: { elemMatch: { id: { in: $workcategories } } }
        }
      }
    ) {
      nodes {
        id
        title
        featuredImage {
          node {
            ...Image
          }
        }
        uri
        relatedContent {
          clientWorkRelationship {
            ... on WpClient {
              title
            }
          }
        }
      }
    }
  }
`;
