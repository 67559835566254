import styled from 'styled-components'
import Label from '../../components/Label'

export const ClientLabel = styled(Label)`
    text-transform: uppercase;
    color: ${props => props.theme.colors.secondary};
`

export const CategoryLabel = styled(Label)`
    margin-right: 1.5em;
    color: ${props => props.theme.colors.tertairy};
`